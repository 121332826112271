<template>
  <div class="imageCropperForProfile">
    <div class="title">Thumbnail Previewer and Uploader</div>
    <input type="file" name="image" accept="image/*"
           class="uploadFile"
           @change="setImage" />
    <div class="ImageCropperBtn" @click.prevent="rotate" v-if="imgSrc != ''">Rotate</div>
    <div class="cropperContainer">
      <vue-cropper ref='cropper' v-show="imgSrc != ''"
                   :guides="true"
                   :view-mode="2"
                   drag-mode="none"
                   :aspectRatio="aspectRatio"
                   :auto-crop-area="0.5"
                   :min-container-width="100"
                   :min-container-height="100"
                   :background="true"
                   :rotatable="true"
                   :src="imgSrc"
                   alt=""
                   :cropend="cropImage"
                   :ready="cropImage"
                   :img-style="{ 'width': '400px', 'height': '400px' }">
      </vue-cropper>
    </div>
    <ImagePreviewerProfile :profile="profileData" :cropImg="cropImg" />
    <ButtonSubmit v-show="cropImg"
                  :copy="'Save Image'"
                  @callback="selectedImage" />
  </div>
</template>

<script>
import VueCropper from 'vue-cropperjs';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cropperjs/dist/cropper.css';

import ButtonSubmit from '../formElements/buttons/buttonSubmit.vue';

import ImagePreviewerProfile from './ImagePreviewerProfile.vue';

export default {
  name: 'imageCropperForProfile',
  props: ['profileData'],
  components: {
    VueCropper,
    ImagePreviewerProfile,
    ButtonSubmit,
  },
  data() {
    return {
      imgSrc: '',
      cropImg: this.profileData.thumbnailImage,
      newWidth: 100,
      aspectRatio: 1,
      story: this.profileData,
      resizedCanvas: null,
      fileName: null,
      fileType: null,
    };
  },
  methods: {
    setImage(e) {
      const file = e.target.files[0];
      this.fileName = file.name;
      this.fileType = file.type;
      if (!file.type.includes('image/')) {
        return;
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      }
    },
    cropImage() {
      const croppedCanvas = this.$refs.cropper.getCroppedCanvas();

      this.resizedCanvas = document.createElement('canvas');
      const resizedContext = this.resizedCanvas.getContext('2d');

      const aspectRatio = croppedCanvas.width / croppedCanvas.height;
      const newHeight = this.newWidth / aspectRatio;

      this.resizedCanvas.height = newHeight;
      this.resizedCanvas.width = this.newWidth;

      const canvas = croppedCanvas;

      resizedContext.drawImage(canvas, 0, 0, this.newWidth, newHeight);
      this.cropImg = this.resizedCanvas.toDataURL();
    },
    rotate() {
      this.$refs.cropper.rotate(90);
    },
    async selectedImage() {
      this.resizedCanvas.toBlob(this.gotBlob);
    },
    async gotBlob(blob) {
      const blobObject = blob;
      const formData = new FormData();
      blobObject.FileName = this.fileName;
      blobObject.ModifiedOn = new Date();
      formData.append('file', blob, this.fileName);
      await this.$emit('updatedImage', formData);
      this.$store.dispatch('buttonSubmitStore/done');
    },
  },
};
</script>

<style lang="scss" scoped>
  .imageCropperForProfile {
    border: .5px solid $LincolnOrange;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    .title {
      text-transform: uppercase;
      padding-bottom: 10px;
    }
    .uploadFile {
      font-size: 1.2em;
      padding: 10px 0;
    }
    .cropperContainer {
      width: 400px;
      height: 400px;
      border: 1px solid gray;
    }
    .imgPreviewContainer {
      display: flex;
      flex-direction: row;
    }
    .imgPreviewContainer > * {
      padding: 10px;
    }
    .ImageCropperBtn {
      background-color: $LincolnOrange;
      color: white;
      width: 100px;
      text-align: center;
      text-transform: uppercase;
      padding: 5px;
      margin-bottom: 5px;
    }
  }
</style>
