<template>
  <div class="imgPreviewContainer">
    <div class="titlePreview">
      Full View
      <div class="img sizeFull"
           :style="setBackgroundImage">
      </div>
    </div>
    <div class="titlePreview">
      Medium View
      <div class="img sizeMedium"
           :style="setBackgroundImage">
      </div>
    </div>
    <div class="titlePreview">
      Small View
      <div class="img sizeSmall"
           :style="setBackgroundImage">
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
export default {
  name: 'ImagePreviewerProfile',
  props: ['profile', 'cropImg'],
  data() {
    return {
      downloadedImage: null,
      defaultImage: require('../../assets/images/temp/LEEDefaultProfileImage.png'),
    };
  },
  computed: {
    setBackgroundImage() {
      let tempPath = '';
      const backgroundImage = {};
      // If there already is an image
      if (this.downloadedImage) {
        tempPath = this.downloadedImage;
      }
      // If a new image is being added
      if (this.cropImg) {
        tempPath = this.cropImg;
      }
      if (this.profile.profileImageStatus === 3 || tempPath === '') {
        tempPath = this.defaultImage;
      }
      backgroundImage['background-image'] = `url(${tempPath})`;
      return backgroundImage;
    },
  },
  methods: {
    async getImage(id) {
      const asset = await this.$store.dispatch(
        'assetStore/downloadAsset',
        id,
      );
      if (asset) {
        this.downloadedImage = window.URL.createObjectURL(new Blob([asset.data]));
      }
    },
  },
  async created() {
    if (this.profile.thumbnailAsset) {
      await this.getImage(this.profile.thumbnailAsset.id);
    }
  },
};
/* eslint-enable global-require */
</script>

<style lang="scss" scoped>
  .imgPreviewContainer {
    display: flex;
    flex-direction: row;
  }

    .imgPreviewContainer > * {
      padding: 10px;
      width: 100px;
    }
  .img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    background-color: $LincolnGrayLighter;
    &.sizeFull {
      height: 100px;
    }
    &.sizeMedium {
      width: 80px;
      height: 80px;
    }
    &.sizeSmall {
      width: 50px;
      height: 50px;
    }
  }
</style>
