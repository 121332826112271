var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "imgPreviewContainer" }, [
    _c("div", { staticClass: "titlePreview" }, [
      _vm._v("\n    Full View\n    "),
      _c("div", { staticClass: "img sizeFull", style: _vm.setBackgroundImage }),
    ]),
    _c("div", { staticClass: "titlePreview" }, [
      _vm._v("\n    Medium View\n    "),
      _c("div", {
        staticClass: "img sizeMedium",
        style: _vm.setBackgroundImage,
      }),
    ]),
    _c("div", { staticClass: "titlePreview" }, [
      _vm._v("\n    Small View\n    "),
      _c("div", {
        staticClass: "img sizeSmall",
        style: _vm.setBackgroundImage,
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }